import { __decorate } from "tslib";
import { updateTextFields } from "@materializecss/materialize";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
let AppTextField = class AppTextField extends Vue {
    constructor() {
        super(...arguments);
        this.touched = false;
        this.localValue = null;
    }
    get isValid() {
        return ((!this.required ||
            (this.required &&
                this.localValue !== null &&
                this.localValue !== "" &&
                this.localValue !== undefined)) &&
            (!this.pattern ||
                new RegExp(this.pattern).test(this.localValue)) &&
            !this.error);
    }
    get dataTestName() {
        return "appTextField-" + this.name;
    }
    mounted() {
        if (this.localValue !== null)
            updateTextFields();
    }
    updated() {
        if (this.localValue !== null)
            updateTextFields();
    }
    onValueChanged(val) {
        if (val !== this.localValue && !this.isStringifiedLocalValue(val)) {
            if (val === null || val === "")
                this.touched = false;
            this.localValue = val;
            updateTextFields();
        }
    }
    onLocalValueChanged(val, oldVal) {
        if (val !== oldVal) {
            if (this.type === "number") {
                if (val === "")
                    val = null;
                if (val !== null && !Number.isNaN(Number(val))) {
                    val = Number(val);
                    const fixedValue = this.fixValue(val);
                    if (this.maxDecimals !== null && val !== fixedValue) {
                        return this.updateValueWithDelay(fixedValue);
                    }
                }
                if (this.min !== null && val < this.min) {
                    return this.updateValueWithDelay(this.min);
                }
                if (this.max !== null && val > this.max) {
                    return this.updateValueWithDelay(this.max);
                }
            }
            this.$emit("input", val);
        }
    }
    onInput(e) {
        if (!this.lazy) {
            const value = e.target.value;
            this.localValue = value;
        }
    }
    onChange(e) {
        if (this.lazy) {
            const value = e.target.value;
            this.localValue = value;
        }
        this.$emit("change", this.localValue);
    }
    onFocus(e) {
        this.$emit("focus", e);
    }
    markAsTouched(e) {
        e.preventDefault();
        this.$emit("blur", e);
        this.touched = true;
    }
    /**
     * HTML input will keep convert numbers to string.
     * If we have a type="number", we have to consider '3' === 3
     * so we dont loop over value formatting
     */
    isStringifiedLocalValue(value) {
        return this.type === "number" && String(value) === String(this.localValue);
    }
    /**
     * This is to make sure the DOM is properly updated
     */
    updateValueWithDelay(value) {
        this.$nextTick(() => {
            this.localValue = value;
        });
    }
    fixValue(value) {
        return Number(value.toFixed(this.maxDecimals));
    }
};
__decorate([
    Prop({ required: true })
], AppTextField.prototype, "name", void 0);
__decorate([
    Prop({ default: "" })
], AppTextField.prototype, "label", void 0);
__decorate([
    Prop({ default: null })
], AppTextField.prototype, "value", void 0);
__decorate([
    Prop({ default: false })
], AppTextField.prototype, "required", void 0);
__decorate([
    Prop({ default: true })
], AppTextField.prototype, "showRequired", void 0);
__decorate([
    Prop({ default: false })
], AppTextField.prototype, "disabled", void 0);
__decorate([
    Prop({ default: false })
], AppTextField.prototype, "readonly", void 0);
__decorate([
    Prop({ default: false })
], AppTextField.prototype, "validate", void 0);
__decorate([
    Prop({ default: "" })
], AppTextField.prototype, "hint", void 0);
__decorate([
    Prop({ default: "" })
], AppTextField.prototype, "error", void 0);
__decorate([
    Prop({ default: null })
], AppTextField.prototype, "pattern", void 0);
__decorate([
    Prop({ default: "any" })
], AppTextField.prototype, "step", void 0);
__decorate([
    Prop({ default: null })
], AppTextField.prototype, "min", void 0);
__decorate([
    Prop({ default: null })
], AppTextField.prototype, "max", void 0);
__decorate([
    Prop({ default: "text" })
], AppTextField.prototype, "type", void 0);
__decorate([
    Prop({ default: false })
], AppTextField.prototype, "compact", void 0);
__decorate([
    Prop({ default: "" })
], AppTextField.prototype, "placeholder", void 0);
__decorate([
    Prop({ default: null })
], AppTextField.prototype, "tabindex", void 0);
__decorate([
    Prop({ default: false })
], AppTextField.prototype, "lazy", void 0);
__decorate([
    Prop({ default: null })
], AppTextField.prototype, "maxDecimals", void 0);
__decorate([
    Watch("value", { immediate: true })
], AppTextField.prototype, "onValueChanged", null);
__decorate([
    Watch("localValue", { immediate: true })
], AppTextField.prototype, "onLocalValueChanged", null);
AppTextField = __decorate([
    Component
], AppTextField);
export default AppTextField;
