/* eslint-disable @typescript-eslint/ban-types */
import { VuexClassModuleFactory, } from "./module-factory";
export function Module(arg) {
    if (typeof arg === "function") {
        return moduleDecoratorFactory()(arg);
    }
    else {
        return moduleDecoratorFactory(arg);
    }
}
function moduleDecoratorFactory(moduleOptions) {
    return (constructor) => {
        const accessor = function (...args) {
            const instance = new constructor.prototype.constructor(...args);
            Object.setPrototypeOf(instance, accessor.prototype);
            const factory = new VuexClassModuleFactory(constructor, instance, moduleOptions || {});
            factory.registerVuexModule((moduleOptions || {}).preserveState || false);
            return factory.buildAccessor();
        };
        accessor.prototype = Object.create(constructor.prototype);
        accessor.prototype.constructor = accessor;
        return accessor;
    };
}
