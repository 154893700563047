import { __decorate } from "tslib";
import { LoginErrorHandler } from "@/helpers/error";
import store from "..";
import userAPI from "../../api/user";
import { getModuleConfig } from "../helpers/get-module-config";
import { StorePersistence } from "../helpers/store-persistence";
import { Action, Module, Mutation, VuexModule, } from "../helpers/vuex-class-modules";
const name = "auth";
let AuthModule = class AuthModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.token = null;
        this.user = null;
    }
    get isLoggedIn() {
        return !!this.token;
    }
    setToken(token) {
        this.token = token;
    }
    setUser(user) {
        this.user = user;
    }
    async login(credentials) {
        try {
            const user = await userAPI.login(credentials);
            if (user.error)
                return;
            this.setUser(user);
            this.setToken(user.token);
        }
        catch (err) {
            new LoginErrorHandler(err).display();
        }
    }
    logout() {
        StorePersistence.clear();
        this.setUser(null);
        this.setToken(null);
    }
};
__decorate([
    Mutation
], AuthModule.prototype, "setToken", null);
__decorate([
    Mutation
], AuthModule.prototype, "setUser", null);
__decorate([
    Action
], AuthModule.prototype, "login", null);
__decorate([
    Action
], AuthModule.prototype, "logout", null);
AuthModule = __decorate([
    Module(getModuleConfig())
], AuthModule);
export { AuthModule };
export const auth = new AuthModule({ store, name });
