import { __decorate } from "tslib";
import indicatorReferencesAPI from "@/api/indicatorReferences";
import { mutateInArray } from "@/helpers/array";
import { handleError } from "@/helpers/error";
import store from "..";
import { getModuleConfig } from "../helpers/get-module-config";
import { Action, Module, Mutation, VuexModule, } from "../helpers/vuex-class-modules";
let IndicatorReferenceModule = class IndicatorReferenceModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.indicatorReferences = null;
    }
    setIndicators(indicators) {
        this.indicatorReferences = indicators;
    }
    setIndicator(indicator) {
        this.indicatorReferences = mutateInArray(this.indicatorReferences, indicator, (c) => c.id, true);
    }
    unsetIndicator(indicatorId) {
        this.indicatorReferences = this.indicatorReferences.filter((c) => c.id !== indicatorId);
    }
    async loadIndicators() {
        try {
            const indicators = await indicatorReferencesAPI.load();
            this.setIndicators(indicators);
        }
        catch (err) {
            handleError(err);
        }
    }
    async upsertIndicator(indicator) {
        const previousIndicator = this.indicatorReferences?.find((c) => c.id === indicator.id);
        try {
            this.setIndicator(indicator);
            await indicatorReferencesAPI.upsert(indicator);
        }
        catch (err) {
            this.unsetIndicator(indicator.id);
            if (previousIndicator)
                this.setIndicator(previousIndicator);
            handleError(err);
        }
    }
    async deleteIndicator(indicatorId) {
        const previousIndicator = this.indicatorReferences.find((c) => c.id === indicatorId);
        try {
            this.unsetIndicator(indicatorId);
            await indicatorReferencesAPI.delete(indicatorId);
        }
        catch (err) {
            if (previousIndicator)
                this.setIndicator(previousIndicator);
            handleError(err);
        }
    }
};
__decorate([
    Mutation
], IndicatorReferenceModule.prototype, "setIndicators", null);
__decorate([
    Mutation
], IndicatorReferenceModule.prototype, "setIndicator", null);
__decorate([
    Mutation
], IndicatorReferenceModule.prototype, "unsetIndicator", null);
__decorate([
    Action
], IndicatorReferenceModule.prototype, "loadIndicators", null);
__decorate([
    Action
], IndicatorReferenceModule.prototype, "upsertIndicator", null);
__decorate([
    Action
], IndicatorReferenceModule.prototype, "deleteIndicator", null);
IndicatorReferenceModule = __decorate([
    Module(getModuleConfig())
], IndicatorReferenceModule);
export { IndicatorReferenceModule };
export const indicatorReferencesModule = new IndicatorReferenceModule({
    store,
    name: "indicatorReferences",
});
