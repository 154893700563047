import { auth } from "@/store/modules/auth";
import { toast } from "@materializecss/materialize";
let previousToastMessage = "";
class ErrorHandler {
    constructor(error) {
        this.error = error;
    }
    static from(error) {
        if (error) {
            if (error.name == "403") {
                return new PermissionErrorHandler(error);
            }
            else if (error.name == "401") {
                return new AuthErrorHandler(error);
            }
            else if (error.name == "409") {
                return new LoginErrorHandler(error);
            }
            else {
                return new ServerErrorHandler(error);
            }
        }
        else if (error.message === "Network Error") {
            return new NetworkErrorHandler(error);
        }
        else {
            return new ErrorHandler(error);
        }
    }
    display() {
        this.toast({
            message: "Oops! Une erreur est survenue, merci de réessayer.",
        });
    }
    /**
     * Show toast message;
     * Prevent displaying the same message multiple times in a 5s debounce time
     */
    toast(content) {
        if (content.message === previousToastMessage)
            return;
        previousToastMessage = content.message;
        toast({ html: content.message });
        setTimeout(() => (previousToastMessage = ""), 5000);
    }
}
function handleError(error) {
    const handler = ErrorHandler.from(error);
    handler.display();
}
export class LoginErrorHandler extends ErrorHandler {
    display() {
        this.toast({ message: "Identifiants incorrects" });
    }
}
class AuthErrorHandler extends ErrorHandler {
    display() {
        auth.setToken(null);
    }
}
class PermissionErrorHandler extends ErrorHandler {
    display() {
        this.toast({
            message: "Permission manquante.",
        });
        console.warn("Missing permission");
    }
}
class ServerErrorHandler extends ErrorHandler {
    display() {
        this.toast({
            message: "Oops! Une erreur est survenue, merci de réessayer.",
        });
        console.error(this.error.response);
    }
}
class NetworkErrorHandler extends ErrorHandler {
    display() {
        this.toast({ message: "Êtes-vous connecté à internet?" });
        console.warn(this.error);
    }
}
export { handleError };
