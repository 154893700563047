import { isRootUserGuard, routeGuard } from "@/helpers/auth";
import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
const router = new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            name: "Landing",
            component: () => import("@/components/Landing.vue"),
        },
        {
            path: "/overview",
            name: "Overview",
            component: () => import("@/components/Overview.vue"),
        },
        {
            path: "/entry",
            name: "Entry",
            component: () => import("@/components/Entry.vue"),
        },
        {
            path: "/settings",
            name: "Settings",
            component: () => import("@/components/settings/Settings.vue"),
            beforeEnter: isRootUserGuard,
            children: [
                {
                    path: "indicators",
                    component: () => import("@/components/settings/IndicatorReferenceList.vue"),
                },
                {
                    path: "indicator-charts",
                    component: () => import("@/components/settings/IndicatorChartReferenceList.vue"),
                },
                {
                    path: "units",
                    component: () => import("@/components/settings/UnitList.vue"),
                },
            ],
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: "smooth",
            };
        }
    },
});
router.beforeEach(routeGuard);
export default router;
