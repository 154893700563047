import AppBtn from "@/components/shared/ui/AppBtn.vue";
// Register plugins
import { BarController, BarElement, BubbleController, CategoryScale, Chart, Filler, Legend, LinearScale, LineController, LineElement, PointElement, Title, Tooltip, } from "chart.js";
// import "@materializecss/materialize/sass/materialize.scss";
import VCalendar from "v-calendar";
import Vue from "vue";
import App from "./App.vue";
import "./directives";
import "./filters";
import { i18n } from "./lang";
// disable until an update strategy is set
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { core } from "./store/modules/core";
Vue.config.productionTip = true;
Vue.use(VCalendar, {});
Vue.component("AppBtn", AppBtn);
// a client event bus accross the vue components
const eventBus = new Vue();
Vue.prototype.$events = eventBus;
/**
 * Order matters, plugins should be registered after native Chart.js elements
 */
Chart.register(BarController, BarElement, LineController, LineElement, LinearScale, PointElement, BubbleController, CategoryScale, Filler, Tooltip, Title, Legend);
console.log("Environment : " + process.env.NODE_ENV);
console.log("Application version : " + core.version);
console.log("Api endpoint :" + process.env.VUE_APP_API_URL);
console.log("Emergency support :" + process.env.VUE_APP_SUPPORT_URL);
const app = new Vue({
    render: (h) => h(App),
    el: "#app",
    i18n,
    router,
    store,
    template: "<App/>",
    components: { App },
});
/**
 * Load app in window object so it can be accessed by cypress
 * to be used in unit tests
 */
if (window.Cypress) {
    window.app = app;
}
