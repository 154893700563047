import { __decorate } from "tslib";
import Layout from "@/components/layout/Layout.vue";
import Login from "@/components/layout/Login.vue";
import { Component, Vue } from "vue-property-decorator";
import { auth } from "./store/modules/auth";
let App = class App extends Vue {
    get isLoggedIn() {
        return auth.isLoggedIn;
    }
};
App = __decorate([
    Component({
        components: {
            Login,
            Layout,
        },
    })
], App);
export default App;
