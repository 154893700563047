import { __decorate } from "tslib";
import { auth } from "@/store/modules/auth";
import { Component, Vue } from "vue-property-decorator";
import AppBtn from "../shared/ui/AppBtn.vue";
import AppTextField from "../shared/ui/AppTextField.vue";
let Login = class Login extends Vue {
    constructor() {
        super(...arguments);
        this.email = "";
        this.password = "";
    }
    get token() {
        return auth.token;
    }
    login() {
        auth.login({ email: this.email, password: this.password });
    }
};
Login = __decorate([
    Component({
        components: {
            AppTextField,
            AppBtn,
        },
    })
], Login);
export default Login;
