export function Mutation(target, key, descriptor) {
    const vuexModule = target.constructor;
    if (!vuexModule.__mutations) {
        vuexModule.__mutations = {};
    }
    const method = descriptor.value;
    if (method) {
        descriptor.value = function (...args) {
            return method.apply(this, args?.map((a) => {
                if (Array.isArray(a))
                    a = a.map((aa) => Object.freeze(aa));
                else if (typeof a === "object")
                    a = Object.freeze(a);
                return a;
            }));
        };
        vuexModule.__mutations[key] = descriptor.value;
    }
}
