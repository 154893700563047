import { copyPartialObject } from "@/helpers/object";
import VuexPersistence from "vuex-persist";
import { version } from "../../../package.json";
import { STORE_KEY } from "../store-key";
export class StorePersistence {
    constructor(state, saveToLocalStorage) {
        this.state = state;
        this.saveToLocalStorage = saveToLocalStorage;
        this.setVuexLocal(state);
    }
    get plugin() {
        return this.vuexLocal.plugin;
    }
    static clear() {
        localStorage.removeItem(STORE_KEY);
    }
    setVuexLocal(state) {
        this.vuexLocal = new VuexPersistence({
            key: STORE_KEY,
            restoreState: (key, storage) => {
                const savedState = storage?.[key] && JSON.parse(storage[key]);
                if (storage && savedState && savedState.core?.version !== version) {
                    return state;
                }
                return savedState || {};
            },
            filter: (mutation) => {
                /**
                 * Do not update local storage on a logout action with a null payload
                 */
                return mutation.type !== "auth/logout" || mutation.payload === null;
            },
            saveState: (key, state, storage) => {
                try {
                    const stateToSave = copyPartialObject(state, this.saveToLocalStorage);
                    const stringifiedState = JSON.stringify(stateToSave);
                    if (stringifiedState.length > 2000000) {
                        console.warn(`Approaching maximum local storage capacity. Consider excluding large dataset by declaring its path in store/config.`);
                    }
                    storage.setItem(key, stringifiedState);
                }
                catch (err) {
                    console.error(`Maximum local storage capacity reached.`, err);
                    storage.removeItem(key);
                }
            },
        });
    }
}
