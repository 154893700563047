import { __decorate } from "tslib";
import { Dropdown } from "@materializecss/materialize";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
let AppBtn = class AppBtn extends Vue {
    constructor() {
        super(...arguments);
        this.dropdownInstance = null;
    }
    /**
     * data-test="" for e2e tests
     */
    get dataTestName() {
        return "appBtn-" + this.id;
    }
    mounted() {
        if (this.dropdown)
            this.dropdownInstance = Dropdown.init(this.$refs.appBtn, this.dropdownOptions);
    }
    beforeDestroy() {
        this.dropdownInstance?.destroy();
    }
    onClick(e) {
        const href = this.$refs.appBtn.href;
        if (!href)
            e.preventDefault();
        if (this.to)
            this.$router.push(this.to);
        return e;
    }
};
__decorate([
    Prop({ required: true })
], AppBtn.prototype, "id", void 0);
__decorate([
    Prop({ default: "a" })
], AppBtn.prototype, "type", void 0);
__decorate([
    Prop({ default: "" })
], AppBtn.prototype, "title", void 0);
__decorate([
    Prop({ type: Boolean, required: false })
], AppBtn.prototype, "disabled", void 0);
__decorate([
    Prop({ default: "" })
], AppBtn.prototype, "label", void 0);
__decorate([
    Prop({ default: "" })
], AppBtn.prototype, "icon", void 0);
__decorate([
    Prop({ default: "" })
], AppBtn.prototype, "iconClass", void 0);
__decorate([
    Prop({ type: Boolean, required: false })
], AppBtn.prototype, "outlined", void 0);
__decorate([
    Prop({ type: Boolean, required: false })
], AppBtn.prototype, "flat", void 0);
__decorate([
    Prop({ type: Boolean, required: false })
], AppBtn.prototype, "waves", void 0);
__decorate([
    Prop({ type: Boolean, required: false })
], AppBtn.prototype, "light", void 0);
__decorate([
    Prop({ type: Boolean, required: false })
], AppBtn.prototype, "floating", void 0);
__decorate([
    Prop({ type: Boolean, required: false })
], AppBtn.prototype, "pulse", void 0);
__decorate([
    Prop({ default: "" })
], AppBtn.prototype, "color", void 0);
__decorate([
    Prop({ default: "" })
], AppBtn.prototype, "textColor", void 0);
__decorate([
    Prop({ default: "" })
], AppBtn.prototype, "size", void 0);
__decorate([
    Prop({ default: null })
], AppBtn.prototype, "to", void 0);
__decorate([
    Prop({ default: "" })
], AppBtn.prototype, "dropdown", void 0);
__decorate([
    Prop({ default: () => ({}) })
], AppBtn.prototype, "dropdownOptions", void 0);
__decorate([
    Emit("click")
], AppBtn.prototype, "onClick", null);
AppBtn = __decorate([
    Component
], AppBtn);
export default AppBtn;
