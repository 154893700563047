import { __decorate } from "tslib";
import unitReferenceAPI from "@/api/unitReferences";
import { mutateInArray } from "@/helpers/array";
import { handleError } from "@/helpers/error";
import store from "..";
import { getModuleConfig } from "../helpers/get-module-config";
import { Action, Module, Mutation, VuexModule, } from "../helpers/vuex-class-modules";
let UnitModule = class UnitModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.unitReferences = null;
    }
    setUnits(units) {
        this.unitReferences = units;
    }
    setUnit(unit) {
        this.unitReferences = mutateInArray(this.unitReferences, unit, (c) => c.id, true);
    }
    unsetUnit(unitId) {
        this.unitReferences = this.unitReferences.filter((c) => c.id !== unitId);
    }
    async loadUnits() {
        try {
            const units = await unitReferenceAPI.load();
            this.setUnits(units);
        }
        catch (err) {
            handleError(err);
        }
    }
    async upsertUnit(unit) {
        const previousUnit = this.unitReferences?.find((c) => c.id === unit.id);
        try {
            this.setUnit(unit);
            await unitReferenceAPI.upsert(unit);
        }
        catch (err) {
            this.unsetUnit(unit.id);
            if (previousUnit)
                this.setUnit(previousUnit);
            handleError(err);
        }
    }
    async deleteUnit(unitId) {
        const previousUnit = this.unitReferences.find((c) => c.id === unitId);
        try {
            this.unsetUnit(unitId);
            await unitReferenceAPI.delete(unitId);
        }
        catch (err) {
            if (previousUnit)
                this.setUnit(previousUnit);
            handleError(err);
        }
    }
};
__decorate([
    Mutation
], UnitModule.prototype, "setUnits", null);
__decorate([
    Mutation
], UnitModule.prototype, "setUnit", null);
__decorate([
    Mutation
], UnitModule.prototype, "unsetUnit", null);
__decorate([
    Action
], UnitModule.prototype, "loadUnits", null);
__decorate([
    Action
], UnitModule.prototype, "upsertUnit", null);
__decorate([
    Action
], UnitModule.prototype, "deleteUnit", null);
UnitModule = __decorate([
    Module(getModuleConfig())
], UnitModule);
export { UnitModule };
export const unitReferencesModule = new UnitModule({
    store,
    name: "unitReferences",
});
