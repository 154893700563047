/**
 * State paths that are excluded from localstorage
 */
export const saveToLocalStorage = [
    "core.version",
    "core.year",
    "core.unitId",
    "core.indicatorId",
    "core.group",
    "auth",
];
