export default {
    en: {
        short: {
            year: "numeric",
            month: "short",
            day: "numeric",
        },
        datetime: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
        },
        long: {
            year: "numeric",
            month: "long",
            day: "numeric",
            weekday: "long",
            hour: "numeric",
            minute: "numeric",
        },
        log: {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
        },
    },
    "fr-FR": {},
};
