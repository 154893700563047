import Vue from "vue";
/**
 * Change background color depending on the score;
 * expected a float between 0 and 1.
 * #c62828 : red darken-3
 * #ef6c00 : orange darken-3
 * #00c853 : green accent-4
 */
/**
 * Accepts only valid number in input
 */
const numberOnlyListener = (e) => {
    const target = e.target;
    const currentValue = target.value;
    const previousValue = currentValue?.slice(0, -1);
    const isValid = (val) => !isNaN(Number(val));
    target.value =
        e.data !== "e" && isValid(currentValue)
            ? currentValue
            : isValid(previousValue)
                ? previousValue
                : "";
};
Vue.directive("numberOnly", {
    bind: function (el) {
        el.addEventListener("input", numberOnlyListener, true);
        el.addEventListener("change", numberOnlyListener, true);
        el.addEventListener("blur", numberOnlyListener, true);
    },
    unbind: function (el) {
        el.removeEventListener("input", numberOnlyListener);
        el.removeEventListener("change", numberOnlyListener);
        el.removeEventListener("blur", numberOnlyListener);
    },
});
