import { __decorate } from "tslib";
import indicatorChartReferencesAPI from "@/api/indicatorChartReferences";
import { mutateInArray } from "@/helpers/array";
import { handleError } from "@/helpers/error";
import store from "..";
import { getModuleConfig } from "../helpers/get-module-config";
import { Action, Module, Mutation, VuexModule, } from "../helpers/vuex-class-modules";
let IndicatorChartReferenceModule = class IndicatorChartReferenceModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.indicatorChartReferences = null;
    }
    setIndicatorCharts(indicatorChartReferences) {
        this.indicatorChartReferences = indicatorChartReferences;
    }
    setIndicatorChart(indicatorChart) {
        this.indicatorChartReferences = mutateInArray(this.indicatorChartReferences, indicatorChart, (c) => c.id, true);
    }
    unsetIndicatorChart(chartId) {
        this.indicatorChartReferences = this.indicatorChartReferences.filter((c) => c.id !== chartId);
    }
    async loadIndicatorCharts() {
        try {
            const indicatorChartReferences = await indicatorChartReferencesAPI.load();
            this.setIndicatorCharts(indicatorChartReferences);
        }
        catch (err) {
            handleError(err);
        }
    }
    async upsertIndicatorChart(indicatorChart) {
        const previousIndicator = this.indicatorChartReferences?.find((c) => c.id === indicatorChart.id);
        try {
            this.setIndicatorChart(indicatorChart);
            await indicatorChartReferencesAPI.upsert(indicatorChart);
        }
        catch (err) {
            this.unsetIndicatorChart(indicatorChart.id);
            if (previousIndicator)
                this.setIndicatorChart(previousIndicator);
            handleError(err);
        }
    }
    async deleteIndicatorChart(chartId) {
        const previousIndicator = this.indicatorChartReferences.find((c) => c.id === chartId);
        try {
            this.unsetIndicatorChart(chartId);
            await indicatorChartReferencesAPI.delete(chartId);
        }
        catch (err) {
            if (previousIndicator)
                this.setIndicatorChart(previousIndicator);
            handleError(err);
        }
    }
};
__decorate([
    Mutation
], IndicatorChartReferenceModule.prototype, "setIndicatorCharts", null);
__decorate([
    Mutation
], IndicatorChartReferenceModule.prototype, "setIndicatorChart", null);
__decorate([
    Mutation
], IndicatorChartReferenceModule.prototype, "unsetIndicatorChart", null);
__decorate([
    Action
], IndicatorChartReferenceModule.prototype, "loadIndicatorCharts", null);
__decorate([
    Action
], IndicatorChartReferenceModule.prototype, "upsertIndicatorChart", null);
__decorate([
    Action
], IndicatorChartReferenceModule.prototype, "deleteIndicatorChart", null);
IndicatorChartReferenceModule = __decorate([
    Module(getModuleConfig())
], IndicatorChartReferenceModule);
export { IndicatorChartReferenceModule };
export const indicatorChartReferencesModule = new IndicatorChartReferenceModule({
    store,
    name: "indicatorChartReferences",
});
