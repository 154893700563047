import { __decorate } from "tslib";
import store from "..";
import { version } from "../../../package.json";
import { getModuleConfig } from "../helpers/get-module-config";
import { Module, Mutation, VuexModule } from "../helpers/vuex-class-modules";
const name = "core";
let CoreModule = class CoreModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.version = version;
        this.showProgressBar = false;
        this.showWorkerProgressBar = false;
        this.year = new Date().getFullYear();
        this.unitId = null;
        this.indicatorId = null;
        this.group = "BSPP";
    }
    setYear(year) {
        this.year = year;
    }
    setUnitId(unitId) {
        this.unitId = unitId;
    }
    setIndicatorId(indicatorId) {
        this.indicatorId = indicatorId;
    }
    setGroup(group) {
        this.group = group;
    }
    setProgressBar(showProgressBar) {
        this.showProgressBar = showProgressBar;
    }
    setWorkerProgressBar(showWorkerProgressBar) {
        this.showWorkerProgressBar = showWorkerProgressBar;
    }
};
__decorate([
    Mutation
], CoreModule.prototype, "setYear", null);
__decorate([
    Mutation
], CoreModule.prototype, "setUnitId", null);
__decorate([
    Mutation
], CoreModule.prototype, "setIndicatorId", null);
__decorate([
    Mutation
], CoreModule.prototype, "setGroup", null);
__decorate([
    Mutation
], CoreModule.prototype, "setProgressBar", null);
__decorate([
    Mutation
], CoreModule.prototype, "setWorkerProgressBar", null);
CoreModule = __decorate([
    Module(getModuleConfig())
], CoreModule);
export { CoreModule };
export const core = new CoreModule({ store, name });
