import Vue from "vue";
import VueI18n from "vue-i18n";
import messages from "./messages";
import dateTimeFormats from "./dateTimeFormats";
import numberFormats from "./numberFormats";
Vue.use(VueI18n);
const locale = navigator.language;
const i18n = new VueI18n({
    fallbackLocale: "en-GB",
    locale: locale,
    messages,
    dateTimeFormats,
    numberFormats,
    /**
     * Disable translation warnings until it is handled
     */
    silentTranslationWarn: true,
});
export { i18n };
