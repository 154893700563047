import { VuexModule } from "./VuexModule";
export class VuexClassModuleFactory {
    constructor(classModule, instance, moduleOptions) {
        this.definition = {
            state: {},
            moduleRefs: {},
            getters: {},
            mutations: {},
            actions: {},
            localFunctions: {},
            localVariables: {},
        };
        this.moduleOptions = moduleOptions;
        this.instance = instance;
        this.registerOptions = instance.__options;
        this.init(classModule);
    }
    init(classModule) {
        // state
        for (const key of Object.keys(this.instance)) {
            const val = this.instance[key];
            if (key !== "__options" &&
                Object.prototype.hasOwnProperty.call(this.instance, key)) {
                if (key.startsWith("$")) {
                    this.definition.localVariables[key] = val;
                }
                else if (val instanceof VuexModule) {
                    this.definition.moduleRefs[key] = val;
                }
                else {
                    this.definition.state[key] = this.instance[key];
                }
            }
        }
        const actionKeys = Object.keys(classModule.__actions || {});
        const mutationKeys = Object.keys(classModule.__mutations || {});
        const isAction = (key) => actionKeys.indexOf(key) !== -1;
        const isMutation = (key) => mutationKeys.indexOf(key) !== -1;
        for (const module of getModulePrototypes(classModule)) {
            for (const key of Object.getOwnPropertyNames(module.prototype)) {
                const descriptor = Object.getOwnPropertyDescriptor(module.prototype, key);
                const isGetter = !!descriptor.get;
                if (isGetter && !(key in this.definition.getters)) {
                    this.definition.getters[key] = descriptor.get;
                }
                if (isAction(key) &&
                    !(key in this.definition.actions) &&
                    descriptor.value) {
                    this.definition.actions[key] = module.prototype[key];
                }
                if (isMutation(key) &&
                    !(key in this.definition.mutations) &&
                    descriptor.value) {
                    this.definition.mutations[key] = module.prototype[key];
                }
                const isHelperFunction = descriptor.value &&
                    typeof module.prototype[key] === "function" &&
                    !isAction(key) &&
                    !isMutation(key) &&
                    key !== "constructor";
                if (isHelperFunction && !(key in this.definition.localFunctions)) {
                    this.definition.localFunctions[key] = module.prototype[key];
                }
            }
        }
    }
    registerVuexModule(preserveState) {
        const vuexModule = {
            state: this.definition.state,
            getters: {},
            mutations: {},
            actions: {},
            namespaced: true,
        };
        // getters
        mapValues(vuexModule.getters, this.definition.getters, (getter) => {
            return (state, getters) => {
                const thisObj = this.buildThisProxy({ state, getters });
                return getter.call(thisObj);
            };
        });
        // mutations
        mapValues(vuexModule.mutations, this.definition.mutations, (mutation) => {
            return (state, payload) => {
                const thisObj = this.buildThisProxy({
                    state,
                    stateSetter: (stateField, val) => {
                        state[stateField] = val;
                    },
                });
                mutation.call(thisObj, payload);
            };
        });
        if (this.moduleOptions.generateMutationSetters) {
            for (const stateKey of Object.keys(this.definition.state)) {
                const mutation = (state, payload) => {
                    state[stateKey] = payload;
                };
                vuexModule.mutations[this.getMutationSetterName(stateKey)] = mutation;
            }
        }
        // actions
        mapValues(vuexModule.actions, this.definition.actions, (action) => {
            return (context, payload) => {
                const proxyDefinition = {
                    ...context,
                    stateSetter: this.moduleOptions.generateMutationSetters
                        ? (field, val) => {
                            context.commit(this.getMutationSetterName(field), val);
                        }
                        : undefined,
                };
                const thisObj = this.buildThisProxy(proxyDefinition);
                return action.call(thisObj, payload);
            };
        });
        // register module
        const { store, name } = this.registerOptions;
        if (preserveState) {
            if (process.env.NODE_ENV === "development" && store.hasModule(name)) {
                store.hotUpdate({
                    modules: {
                        [name]: vuexModule,
                    },
                });
            }
            else {
                const previousState = { ...(store.state[name] || {}) };
                store.registerModule(this.registerOptions.name, vuexModule);
                const s = store.state;
                store.replaceState({
                    ...s,
                    [name]: {
                        ...(s[name] || {}),
                        ...previousState,
                    },
                });
            }
        }
        else {
            if (store.state[name]) {
                if (VuexModule.__useHotUpdate ||
                    (typeof module !== "undefined" && module.hot)) {
                    store.hotUpdate({
                        modules: {
                            [name]: vuexModule,
                        },
                    });
                }
                else {
                    throw Error(`[vuex-class-module]: A module with name '${name}' already exists.`);
                }
            }
            else {
                store.registerModule(this.registerOptions.name, vuexModule);
            }
        }
    }
    buildAccessor() {
        const { store, name } = this.registerOptions;
        const stateSetter = this.moduleOptions.generateMutationSetters
            ? (field, val) => {
                store.commit(`${name}/${this.getMutationSetterName(field)}`, val);
            }
            : undefined;
        const accessorModule = this.buildThisProxy({
            ...store,
            state: store.state[name],
            stateSetter,
            useNamespaceKey: true,
            excludeModuleRefs: true,
            excludeLocalFunctions: true,
            excludeLocalVariables: true,
        });
        // watch API
        accessorModule.$watch = (fn, callback, options) => {
            return store.watch((state, getters) => fn(this.buildThisProxy({
                state: state[name],
                getters,
                useNamespaceKey: true,
            })), callback, options);
        };
        Object.setPrototypeOf(accessorModule, Object.getPrototypeOf(this.instance));
        Object.freeze(accessorModule);
        return accessorModule;
    }
    buildThisProxy(proxyDefinition) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const obj = {};
        if (proxyDefinition.state) {
            mapValuesToProperty(obj, this.definition.state, (key) => proxyDefinition.state[key], proxyDefinition.stateSetter
                ? (key, val) => proxyDefinition.stateSetter(key, val)
                : () => {
                    throw Error("[vuex-class-module]: Cannot modify state outside mutations.");
                });
        }
        if (!proxyDefinition.excludeModuleRefs) {
            mapValues(obj, this.definition.moduleRefs, (val) => val);
        }
        if (!proxyDefinition.excludeLocalVariables) {
            mapValues(obj, this.definition.localVariables, (val) => val);
        }
        const namespaceKey = proxyDefinition.useNamespaceKey
            ? this.registerOptions.name + "/"
            : "";
        if (proxyDefinition.getters) {
            mapValuesToProperty(obj, this.definition.getters, (key) => proxyDefinition.getters[`${namespaceKey}${key}`]);
        }
        if (proxyDefinition.commit) {
            mapValues(obj, this.definition.mutations, (mutation, key) => {
                return (payload) => proxyDefinition.commit(`${namespaceKey}${key}`, payload);
            });
        }
        if (proxyDefinition.dispatch) {
            mapValues(obj, this.definition.actions, (action, key) => {
                return (payload) => proxyDefinition.dispatch(`${namespaceKey}${key}`, payload);
            });
        }
        if (!proxyDefinition.excludeLocalFunctions) {
            mapValues(obj, this.definition.localFunctions, (localFunction) => {
                return (...args) => localFunction.apply(obj, args);
            });
        }
        return obj;
    }
    getMutationSetterName(stateKey) {
        return "set__" + stateKey;
    }
}
function mapValues(target, source, mapFunc) {
    for (const key of Object.keys(source)) {
        target[key] = mapFunc(source[key], key);
    }
}
function mapValuesToProperty(target, source, get, set) {
    for (const key of Object.keys(source)) {
        Object.defineProperty(target, key, {
            get: () => get(key),
            set: set ? (val) => set(key, val) : undefined,
        });
    }
}
function getModulePrototypes(module) {
    const prototypes = [];
    for (let prototype = module; prototype && prototype !== VuexModule; prototype = Object.getPrototypeOf(prototype)) {
        prototypes.push(prototype);
    }
    return prototypes;
}
