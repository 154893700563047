export function Action(target, key, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
descriptor) {
    const vuexModule = target.constructor;
    if (!vuexModule.__actions) {
        vuexModule.__actions = {};
    }
    if (descriptor.value) {
        vuexModule.__actions[key] = descriptor.value;
    }
}
