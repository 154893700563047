// import "./interceptors";
import { handleError } from "@/helpers/error";
import { auth } from "@/store/modules/auth";
const TOKEN_HEADER = process.env.VUE_APP_TOKEN_HEADER;
export class Fetch {
    static async http(path, config) {
        config.headers = {
            "Content-Type": "application/json",
            ...(config.headers || {}),
        };
        if (auth.token)
            config.headers.jwtauth = auth.token;
        const response = await fetch(`${process.env.VUE_APP_API_URL}/${path}`, config);
        if (!response.ok) {
            const error = new Error(response.statusText);
            error.name = String(response.status);
            handleError(error);
        }
        // may error if there is no body, return empty array
        return response.json().catch(() => ({}));
    }
    static async get(path, config) {
        const init = { method: "get", ...config };
        return await Fetch.http(path, init);
    }
    static async post(path, body, config) {
        const init = { method: "post", body: JSON.stringify(body), ...config };
        return await Fetch.http(path, init);
    }
    static async put(path, body, config) {
        const init = { method: "put", body: JSON.stringify(body), ...config };
        return await Fetch.http(path, init);
    }
    static async delete(path, config) {
        const init = { method: "delete", ...config };
        return await Fetch.http(path, init);
    }
}
