export class VuexModule {
    constructor(options) {
        this.__options = options;
    }
    $watch(fn, callback, options) {
        return function () {
            // placeholder
        };
    }
}
VuexModule.__useHotUpdate = false;
