import store from "@/store";
import { auth } from "@/store/modules/auth";
/**
 * Auth guard
 * Set after login redirect if trying to access a private page when not logged in
 * Only allow access to public pages for not authenticated users
 */
export const routeGuard = async (to, from, next) => {
    if (to.fullPath === "/" ||
        to.matched.some((record) => record.meta.public) ||
        auth.isLoggedIn) {
        next();
    }
    else
        next("/");
};
export const isRootUserGuard = async (to, from, next) => {
    if (auth.user && !auth.user.level) {
        next();
    }
    else
        next("/");
};
function waitForStore(getter) {
    console.log("waitForStore 1");
    const target = getter(store.state, store.getters);
    console.log("waitForStore 2", target);
    return new Promise((resolve) => {
        console.log("waitForStore 3");
        if (target === null || target === undefined) {
            console.log("waitForStore 4");
            const storeWatcher = store.watch(getter, async (value) => {
                console.log("waitForStore 44", value);
                storeWatcher();
                resolve(value);
            });
        }
        else {
            console.log("waitForStore 5");
            resolve(target);
        }
    });
}
