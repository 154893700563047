/**
 * Get a true clone of an object
 * Works only for objects without functions
 */
export function clone(obj) {
    return JSON.parse(JSON.stringify(obj));
}
/**
 * Access object property with its path
 */
export function resolvePath(obj, path, defaultValue) {
    return path.split(".").reduce((o, p) => {
        return o ? (typeof o[p] === "function" ? o[p]() : o[p]) : defaultValue;
    }, obj);
}
export function replaceNestedObj(obj, path, value) {
    return path.split(".").reduce((o, p, i, all) => {
        if (i === all.length - 1 && o && o[p])
            o[p] = value;
        return o && o[p];
    }, obj);
}
export function replaceNestedObjProperty(obj, path, property, value) {
    return path.split(".").reduce((o, p, i, all) => {
        if (i === all.length - 1 && o && Array.isArray(o[p]))
            o[p].map((item) => {
                item[property] = value;
                return item;
            });
        return o && o[p];
    }, obj);
}
export function isObject(obj) {
    return typeof obj === "object" && !Array.isArray(obj) && obj !== null;
}
/**
 * Checks if object has value at specified path
 */
export function hasObjDeepValue(el, path, value, targetFormatter = (v) => v) {
    const [key, mapKey] = path.split(".");
    const target = key ? el[key] : el;
    const isArray = Array.isArray(target);
    const isObject = !isArray && target !== null && typeof target === "object";
    if (!isArray && !isObject)
        return targetFormatter(target) === value;
    else if (isArray)
        return target
            .map((t) => targetFormatter(mapKey ? t[mapKey] : t))
            .includes(value);
    else if (isObject)
        return target[mapKey] === value;
}
/**
 * Method to add and remove keys from origin to target
 * without changing the values of the properties
 */
export function syncObjectKeys(from, to, defaultValue = null) {
    from = { ...(from || {}) };
    to = { ...(to || {}) };
    const fromKeys = Object.keys(from);
    for (const key of fromKeys) {
        if (!to[key])
            to[key] = defaultValue;
    }
    const toKeys = Object.keys(to);
    for (const key of toKeys) {
        if (from[key] === undefined)
            delete to[key];
    }
    return to;
}
export function freezeArrItems(arr) {
    return arr ? arr.map((i) => Object.freeze(i)) : arr;
}
export function freezeObjItems(obj) {
    return obj
        ? Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, Object.freeze(value)]))
        : obj;
}
export function copyPartialObject(source, paths) {
    const target = {};
    const setValue = (t, s, p, i, all) => {
        if (!t || !s)
            return;
        if (s[p] && all[i + 1]) {
            t[p] = t[p] ?? {};
            return setValue(t[p], s[p], all[i + 1], i + 1, all);
        }
        else if (s[p]) {
            t[p] = s[p];
        }
    };
    for (const path of paths) {
        const ps = path.split(".");
        setValue(target, source, ps[0], 0, ps);
    }
    return target;
}
