import { __decorate } from "tslib";
import { FormSelect } from "@materializecss/materialize";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
let AppSelect = class AppSelect extends Vue {
    constructor() {
        super(...arguments);
        this.localValue = null;
        this.onDropdownOpenValue = null;
        this.shouldResetOnClose = false;
    }
    get selectData() {
        return {
            multiple: this.multiple,
            options: this.options,
            items: this.items,
            required: this.required,
            disabled: this.disabled,
        };
    }
    get dataTestName() {
        return "appSelect-" + this.name;
    }
    get ungroupedItems() {
        return this.items?.filter((i) => !i.group);
    }
    get groups() {
        return this.items
            ?.reduce((acc, curr) => {
            if (curr.group && !acc.includes(curr.group))
                acc.push(curr.group);
            return acc;
        }, [])
            ?.sort(this.sortGroupFn);
    }
    created() {
        this.resetValue();
    }
    mounted() {
        this.initSelect();
    }
    beforeDestroy() {
        this.destroySelect();
    }
    /**
     * Handle the validation manually as Materialize does not handle it for selects
     */
    onChangeEvent(e) {
        if (this.validate) {
            const select = e.target;
            const materializeWrapper = select.parentElement;
            if (materializeWrapper.classList.contains("select-wrapper")) {
                if (select.validity.valid) {
                    materializeWrapper.classList.remove("invalid");
                    materializeWrapper.classList.add("valid");
                }
                else {
                    materializeWrapper.classList.remove("valid");
                    materializeWrapper.classList.add("invalid");
                }
            }
        }
    }
    initSelect() {
        const el = this.$refs.selectContainer;
        FormSelect.init(el.querySelector("select"), {
            ...this.options,
            dropdownOptions: {
                ...this.options.dropdownOptions,
                onOpenStart: () => {
                    this.onDropdownOpenValue = this.localValue;
                    this.addDataTest();
                    this.addBadges();
                },
                onCloseEnd: () => {
                    if (this.localValue !== this.onDropdownOpenValue)
                        this.$emit("closed", this.localValue);
                    this.onDropdownOpenValue = null;
                    if (this.shouldResetOnClose) {
                        this.shouldResetOnClose = false;
                        this.resetSelect();
                    }
                },
            },
        });
    }
    destroySelect() {
        this.getSelectInstance()?.destroy();
    }
    resetValue() {
        this.localValue = this.multiple
            ? [...(this.value || [])]
            : this.value;
    }
    resetSelect() {
        this.destroySelect();
        this.initSelect();
    }
    addDataTest() {
        const el = this.$refs.selectContainer;
        const lis = el.querySelectorAll("li");
        lis.forEach((li) => {
            const label = li.innerText?.trim();
            const value = this.items.find((i) => i.label === label)?.value ?? label;
            li.dataset.test = String(value);
        });
    }
    addBadges() {
        const el = this.$refs.selectContainer;
        const lis = el.querySelectorAll("li");
        lis.forEach((li) => {
            const label = li.innerText?.trim();
            const badge = this.items.find((i) => i.label === label)?.badge;
            if (badge) {
                const badgeEl = document.createElement("span");
                badgeEl.innerHTML = String(badge);
                badgeEl.classList.add("app-select-badge");
                const container = li.firstElementChild;
                container.classList.add("app-select-badge-container");
                container.appendChild(badgeEl);
            }
        });
    }
    getSelectInstance() {
        const el = this.$refs.selectContainer;
        return (el?.querySelector("select") &&
            FormSelect.getInstance(el.querySelector("select")));
    }
    getGroupItems(group) {
        return this.items?.filter((i) => i.group === group);
    }
    onDataChanged(val, oldVal) {
        if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
            this.$nextTick(() => {
                if (this.getSelectInstance()?.dropdown?.isOpen &&
                    this.updateOnSelectDataChanged === "onclose")
                    this.shouldResetOnClose = true;
                else {
                    this.resetSelect();
                }
            });
        }
    }
    onValueChanged(val) {
        if (JSON.stringify(val) !== JSON.stringify(this.localValue)) {
            this.resetValue();
            this.$nextTick(() => this.resetSelect());
        }
    }
    onLocalValueChanged(val, oldVal) {
        if ((oldVal === null && val !== null && val !== "") ||
            (val !== null &&
                oldVal !== null &&
                JSON.stringify(val) !== JSON.stringify(oldVal)))
            this.$emit("input", val);
    }
};
__decorate([
    Prop({ default: "" })
], AppSelect.prototype, "label", void 0);
__decorate([
    Prop({ default: () => ({}) })
], AppSelect.prototype, "options", void 0);
__decorate([
    Prop({ default: () => [] })
], AppSelect.prototype, "items", void 0);
__decorate([
    Prop({ default: null })
], AppSelect.prototype, "value", void 0);
__decorate([
    Prop({ default: false })
], AppSelect.prototype, "required", void 0);
__decorate([
    Prop({ default: true })
], AppSelect.prototype, "showRequired", void 0);
__decorate([
    Prop({ default: false })
], AppSelect.prototype, "multiple", void 0);
__decorate([
    Prop({ default: false })
], AppSelect.prototype, "disabled", void 0);
__decorate([
    Prop({ default: false })
], AppSelect.prototype, "validate", void 0);
__decorate([
    Prop({ required: true })
], AppSelect.prototype, "name", void 0);
__decorate([
    Prop({ default: "" })
], AppSelect.prototype, "hint", void 0);
__decorate([
    Prop({ default: "" })
], AppSelect.prototype, "error", void 0);
__decorate([
    Prop({ default: false })
], AppSelect.prototype, "compact", void 0);
__decorate([
    Prop({ default: undefined })
], AppSelect.prototype, "sortGroupFn", void 0);
__decorate([
    Prop({ default: "immediate" })
], AppSelect.prototype, "updateOnSelectDataChanged", void 0);
__decorate([
    Watch("selectData")
], AppSelect.prototype, "onDataChanged", null);
__decorate([
    Watch("value")
], AppSelect.prototype, "onValueChanged", null);
__decorate([
    Watch("localValue")
], AppSelect.prototype, "onLocalValueChanged", null);
AppSelect = __decorate([
    Component
], AppSelect);
export default AppSelect;
