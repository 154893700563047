export function arrayRemove(arr, i) {
    if (delete arr[i]) {
        return arr.filter((x) => typeof x !== "undefined");
    }
    else {
        throw new Error("Out of bound index");
    }
}
export function arrayMoveUp(arr, i) {
    const c = arr[i];
    if (i > 0) {
        arr[i] = arr[i - 1];
        arr[i - 1] = c;
    }
    return Array.from(arr);
}
export function arrayMoveDown(arr, i) {
    const c = arr[i];
    if (i < arr.length - 1) {
        arr[i] = arr[i + 1];
        arr[i + 1] = c;
    }
    return Array.from(arr);
}
export function byKey(a, b) {
    if (a.key < b.key) {
        return -1;
    }
    if (a.key > b.key) {
        return 1;
    }
    return 0;
}
export function descByKey(a, b) {
    if (a.key < b.key) {
        return 1;
    }
    if (a.key > b.key) {
        return -1;
    }
    return 0;
}
export function groupBy(array, key) {
    const map = new Map();
    const groupBy = (key, value) => {
        if (map.has(key)) {
            const values = map.get(key);
            values.push(value);
            map.set(key, values);
        }
        else {
            map.set(key, [value]);
        }
    };
    array.forEach((item) => {
        groupBy(item[key], item);
    });
    const groups = Array.from(map.entries());
    return groups;
}
export function getSortedIndexes(what, sortFunction) {
    return what
        .map((val, index) => {
        return { index, val };
    })
        .sort((a, b) => {
        return sortFunction(a.val, b.val);
    })
        .map((obj) => obj.index);
}
export function sortByIndexes(what, indexes) {
    const sorted = Array(what.length);
    indexes.forEach((index, i) => {
        sorted[i] = what[index];
    });
    return sorted;
}
export function deduplicate(array) {
    return [...new Set(array)];
}
export function convertToArray(el) {
    return !Array.isArray(el) ? [el] : el;
}
/**
 * Replace <newElem> into <coll> by comparing them with <accessorFn>. If not found, do nothing,
 * or if <pushIfNotFound> is true, add it at queue postion
 */
export function replaceInArray(coll, newElem, accessorFn, pushIfNotFound) {
    !coll && (coll = []);
    const key = accessorFn(newElem);
    const index = coll.findIndex((elem) => accessorFn(elem) === key);
    const collCopy = coll.slice();
    if (index > -1) {
        collCopy[index] = newElem;
    }
    else if (pushIfNotFound) {
        collCopy.push(newElem);
    }
    return collCopy;
}
export function mutateInArray(coll, newElem, accessorFn, pushIfNotFound) {
    !coll && (coll = []);
    const key = accessorFn(newElem);
    const index = coll.findIndex((elem) => accessorFn(elem) === key);
    if (index > -1) {
        coll.splice(index, 1, newElem);
    }
    else if (pushIfNotFound) {
        coll.push(newElem);
    }
    return coll;
}
export class Distribution {
    constructor(_array) {
        this._distribution = _array.sort((a, b) => a - b);
    }
    getDecile(val) {
        const i = this._distribution.findIndex((_) => _ >= val);
        const len = this._distribution.length;
        const decile = (i >= 0 ? i + 1 : len) / len;
        return decile;
    }
}
export function chunkify(a, n, balanced) {
    if (n < 2)
        return [a];
    const len = a.length;
    const out = [];
    let i = 0, size;
    if (len % n === 0) {
        size = Math.floor(len / n);
        while (i < len) {
            out.push(a.slice(i, (i += size)));
        }
    }
    else if (balanced) {
        while (i < len) {
            size = Math.ceil((len - i) / n--);
            out.push(a.slice(i, (i += size)));
        }
    }
    else {
        n--;
        size = Math.floor(len / n);
        if (len % size === 0)
            size--;
        while (i < size * n) {
            out.push(a.slice(i, (i += size)));
        }
        out.push(a.slice(size * n));
    }
    return out;
}
