import { i18n } from "@/lang";
import moment from "moment";
import Vue from "vue";
import string from "@/helpers/string";
function pluralize(amount, word) {
    return string.pluralize(amount, word);
}
function lowercase(word) {
    if (!word)
        return "";
    return word.toLowerCase();
}
function uppercase(word) {
    if (!word)
        return "";
    return word.toUpperCase();
}
function capitalizeFirst(word) {
    if (!word)
        return "";
    return string.capitalizeFirst(word);
}
function round(value, decimal = 2) {
    if (!value)
        return "";
    return value.toFixed(decimal);
}
export function percent(value, returnOriginal = false, format = "percent") {
    if (!isNaN(value) && value !== null)
        return i18n.n(value, format);
    else
        return returnOriginal ? value : "-";
}
export function importancePercent(value, returnOriginal = false) {
    if (value < 0.01) {
        return "<1%";
    }
    else {
        return percent(value, returnOriginal);
    }
}
function variation(value, escape = "", short = false) {
    return !isNaN(value)
        ? i18n.n(value, short ? "variationShort" : "variation")
        : escape;
}
function variationShort(value, escape = "") {
    return variation(value, escape, true);
}
function revenue(value) {
    if (value >= 0)
        return i18n.n(value, "revenue");
    else
        return `(${i18n.n(-1 * value, "revenue")})`;
}
function ARPU(value) {
    return !isNaN(value) ? `${i18n.n(value, "ARPU")}` : "-";
}
function rate(value) {
    return !isNaN(value) ? `${i18n.n(value, "rate")}%` : "";
}
function currency(value) {
    if (isNaN(value))
        return "";
    if (value < 0)
        return `(${i18n.n(Math.abs(value), "currency")})`;
    else
        return i18n.n(Math.abs(value), "currency");
}
function currencyShort(value) {
    if (isNaN(value))
        return "";
    if (value < 0)
        return `(${i18n.n(Math.abs(value), "currencyShort")})`;
    else
        return i18n.n(Math.abs(value), "currencyShort");
}
function currencyVariation(value) {
    if (isNaN(value))
        return "";
    else
        return i18n.n(value, "currencyVariation");
}
function largeNumber(value, escape = "") {
    return !isNaN(value) ? i18n.n(value, "largeNumber") : escape;
}
function decimal(value) {
    return !isNaN(value) ? i18n.n(value) : "";
}
function number(value) {
    return !isNaN(value) ? i18n.n(value, "number") : "";
}
function year(value) {
    return !isNaN(value) ? Math.trunc(value) : "";
}
function numberGreater(value, max) {
    return !isNaN(value)
        ? value > max
            ? `>${i18n.n(max, "number")}`
            : i18n.n(value, "number")
        : "";
}
function volume(value) {
    return value >= 0
        ? i18n.n(value, "number")
        : `(${i18n.n(Math.abs(value), "number")})`;
}
function format(value, formatter = "volume") {
    const formatters = {
        revenue: revenue,
        volume: volume,
        ARPU: ARPU,
        percent: percent,
        number: number,
        rate: rate,
    };
    return formatters[formatter](value);
}
function truncate(text, length, clamp) {
    if (!text)
        return "";
    clamp = clamp || "...";
    length = length || 10;
    const node = document.createElement("div");
    node.innerHTML = text;
    const content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
}
export function date(value, style = "MMM-YY") {
    if (!value)
        return "";
    const m = moment(value);
    return m.format(style);
}
export function LxM(period) {
    return i18n.t("LxM", { x: period === 12 ? "T" : period });
}
export function elapse(to, from, unit = "minutes", showSeconds = false) {
    if (to && from) {
        if (unit === "minutes" && showSeconds) {
            const delta = moment(to).diff(moment(from), unit);
            return delta < 1
                ? `${moment(to).diff(moment(from), "seconds")}s`
                : `${delta}min`;
        }
        else {
            return moment(to).diff(moment(from), unit);
        }
    }
    else {
        return "";
    }
}
export function duration(
// Format seconds to minutes
value, from = "seconds", to = "minutes") {
    if (value >= 0 && from === "seconds" && to === "minutes") {
        return value < 60 ? `${value}s` : `${Math.round(value / 60)}min`;
    }
}
// Add global filters
Vue.filter("pluralize", pluralize);
Vue.filter("lowercase", lowercase);
Vue.filter("uppercase", uppercase);
Vue.filter("capitalizeFirst", capitalizeFirst);
Vue.filter("round", round);
Vue.filter("percent", percent);
Vue.filter("importancePercent", importancePercent);
Vue.filter("truncate", truncate);
Vue.filter("date", date);
Vue.filter("currency", currency);
Vue.filter("currencyShort", currencyShort);
Vue.filter("currencyVariation", currencyVariation);
Vue.filter("largeNumber", largeNumber);
Vue.filter("number", number);
Vue.filter("volume", volume);
Vue.filter("decimal", decimal);
Vue.filter("revenue", revenue);
Vue.filter("ARPU", ARPU);
Vue.filter("rate", rate);
Vue.filter("mix", rate);
Vue.filter("variation", variation);
Vue.filter("variationShort", variationShort);
Vue.filter("format", format);
Vue.filter("elapse", elapse);
Vue.filter("duration", duration);
Vue.filter("numberGreater", numberGreater);
Vue.filter("year", year);
Vue.filter("LxM", LxM);
