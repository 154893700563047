import { __decorate } from "tslib";
import { auth } from "@/store/modules/auth";
import { core } from "@/store/modules/core";
import { Sidenav } from "@materializecss/materialize";
import { Component, Vue } from "vue-property-decorator";
let SideMenu = class SideMenu extends Vue {
    constructor() {
        super(...arguments);
        this.sidenav = null;
    }
    get appVersion() {
        return core.version;
    }
    get apiVersion() {
        return core.version;
    }
    get isRootUser() {
        return auth.user && !auth.user.level;
    }
    get environment() {
        return process.env.NODE_ENV;
    }
    mounted() {
        this.sidenav = Sidenav.init(this.$refs.sidenav, {
            edge: "left",
        });
    }
    beforeDestroy() {
        this.sidenav?.destroy();
    }
    close(e) {
        e.preventDefault();
        this.sidenav?.close();
    }
    logout(e) {
        e?.preventDefault();
        this.close(e);
        auth.logout();
    }
};
SideMenu = __decorate([
    Component
], SideMenu);
export default SideMenu;
