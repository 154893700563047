import { Fetch } from "./fetch";
const url = "indicator-references";
export default {
    load() {
        return Fetch.get(url);
    },
    upsert(body) {
        return Fetch.put(url, body);
    },
    delete(id) {
        return Fetch.delete(`${url}/${id}`);
    },
};
