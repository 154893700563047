import Vue from "vue";
import Vuex from "vuex";
import { saveToLocalStorage } from "./config";
import { StorePersistence } from "./helpers/store-persistence";
Vue.use(Vuex);
/**
 * Create store
 */
export function getStore() {
    const storePersistence = new StorePersistence({}, saveToLocalStorage);
    const store = new Vuex.Store({
        strict: process.env.NODE_ENV === "development",
        plugins: [storePersistence.plugin],
    });
    return store;
}
export default getStore();
