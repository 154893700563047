// Reference : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
export default {
    "en-GB": {
        currency: {
            style: "currency",
            currency: "EUR",
            notation: "compact",
            minimumFractionDigits: 1,
            maximumFractionDigits: 2,
        },
        currencyShort: {
            style: "currency",
            currency: "EUR",
            notation: "compact",
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
        },
        currencyVariation: {
            style: "currency",
            currency: "EUR",
            notation: "compact",
            minimumFractionDigits: 1,
            maximumFractionDigits: 2,
            signDisplay: "exceptZero",
        },
        percent: {
            style: "percent",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        },
        percentMedium: {
            style: "percent",
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
        },
        percentLong: {
            style: "percent",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        },
        mix: {
            style: "percent",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        },
        variation: {
            style: "percent",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
            signDisplay: "exceptZero",
        },
        variationShort: {
            style: "percent",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            signDisplay: "exceptZero",
        },
        variationLong: {
            style: "percent",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
            signDisplay: "exceptZero",
        },
        rate: {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
        },
        number: {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        },
        numberDetailed: {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        },
        numberVeryDetailed: {
            minimumFractionDigits: 0,
            maximumFractionDigits: 4,
        },
        volume: {
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
        },
        revenue: {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
        },
        ARPU: {
            style: "currency",
            currency: "EUR",
            notation: "compact",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
        largeNumber: {
            notation: "compact",
            compactDisplay: "short",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        },
    },
};
