import { __decorate } from "tslib";
import { core } from "@/store/modules/core";
import { indicatorChartReferencesModule } from "@/store/modules/indicator-chart-references";
import { indicatorReferencesModule } from "@/store/modules/indicator-references";
import { unitReferencesModule } from "@/store/modules/unit-references";
import { Component, Vue } from "vue-property-decorator";
import AppBtn from "../shared/ui/AppBtn.vue";
import AppSelect from "../shared/ui/AppSelect.vue";
import SideMenu from "./nav/SideMenu.vue";
let Layout = class Layout extends Vue {
    constructor() {
        super(...arguments);
        this.menu = null;
        this.subMenu = null;
        this.loaded = true;
    }
    get indicatorId() {
        return core.indicatorId;
    }
    set indicatorId(indicatorId) {
        core.setIndicatorId(indicatorId);
    }
    get indicatorItems() {
        return indicatorReferencesModule.indicatorReferences?.map((indicator) => ({
            label: `${indicator.name}`,
            value: indicator.id,
        }));
    }
    get year() {
        return core.year;
    }
    get showProgressBar() {
        return core.showProgressBar;
    }
    get showWorkerProgressBar() {
        return core.showWorkerProgressBar;
    }
    mounted() {
        this.$events.$on("menu-change", (options) => {
            this.menu = options.menu;
            this.subMenu = options.subMenu;
        });
        this.$nextTick(() => {
            this.loadUserContext();
        });
    }
    changeYear(direction) {
        if (direction === "previous")
            core.setYear(core.year - 1);
        else if (direction === "next")
            core.setYear(core.year + 1);
    }
    async loadUserContext() {
        await unitReferencesModule.loadUnits();
        await indicatorReferencesModule.loadIndicators();
        await indicatorChartReferencesModule.loadIndicatorCharts();
        if (!core.unitId && unitReferencesModule.unitReferences?.length)
            core.setUnitId(unitReferencesModule.unitReferences[0].id);
        if (!core.indicatorId &&
            indicatorReferencesModule.indicatorReferences?.length)
            core.setIndicatorId(indicatorReferencesModule.indicatorReferences[0].id);
    }
};
Layout = __decorate([
    Component({
        components: {
            SideMenu,
            AppBtn,
            AppSelect,
        },
    })
], Layout);
export default Layout;
